import { Layout } from "antd";
import { Content, Footer } from "antd/es/layout/layout";
import * as React from "react";
import { Header } from "./header";
import { createHashRouter, Navigate, RouterProvider } from "react-router-dom";
import { NotFoundPage } from "./pages/notFound";
import { BasePage } from "./pages/base";

const router = createHashRouter([
	{
		element: <BasePage />,
		children: [
			{
				path: "*",
				element: <NotFoundPage />,
			},
			{
				path: "/",
				element: <Navigate to="/dashboard" />,
			},
			{
				path: "/transactions",
				element: "",
			},
		],
	},
]);

export function App() {
	return (
		<React.StrictMode>
			<RouterProvider router={router} />
		</React.StrictMode>
	);
}
