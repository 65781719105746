import { theme } from "antd";
import * as React from "react";

export function Container(props: React.PropsWithChildren<{}>) {
	const {
		token: { colorBgContainer, borderRadiusLG },
	} = theme.useToken();

	return (
		<>
			<div
				style={{
					background: colorBgContainer,
					minHeight: 280,
					padding: 24,
					borderRadius: borderRadiusLG,
				}}
			>
				{props.children}
			</div>
		</>
	);
}
