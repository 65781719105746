import { Layout } from "antd";
import * as React from "react";
import { Header } from "../header";
import { Content, Footer } from "antd/es/layout/layout";
import { Outlet } from "react-router-dom";

export function BasePage() {
	return (
		<Layout>
			<Header />
			<Content style={{ minHeight: 120 }}>
				<Outlet />
			</Content>
			<Footer>©2024</Footer>
		</Layout>
	);
}
