import { Typography } from "antd";
import * as React from "react";
import { Container } from "../container";

export function NotFoundPage() {
	return (
		<Container>
			<Typography.Title level={2}>Not found</Typography.Title>
			<Typography>
				The request page was not found. You can go back or return the homepage.
			</Typography>
		</Container>
	);
}
