import * as React from "react";
import { Header as AntdHeader } from "antd/es/layout/layout";
import {
	AppstoreOutlined,
	MailOutlined,
	SettingOutlined,
} from "@ant-design/icons";
import { Menu, type MenuProps } from "antd";
import { useState } from "react";
import { Link, useLocation } from "react-router-dom";

type MenuItem = Required<MenuProps>["items"][number];

const items: MenuItem[] = [
	{
		label: <Link to="dashboard">{"Dashboard"}</Link>,
		key: "dashboard",
		icon: <MailOutlined />,
	},
	{
		label: <Link to="transactions">{"Transactions"}</Link>,
		key: "transactions",
		icon: <MailOutlined />,
	},
	{
		label: <Link to="report">{"Report"}</Link>,
		key: "report",
		icon: <MailOutlined />,
	},
];

export function Header() {
	const location = useLocation();
	const current = React.useMemo(() => {
		const match = /^\/([^\/]+)/.exec(location.pathname);
		if (!match) return "";
		return match[1];
	}, [location.pathname]);

	return (
		<AntdHeader>
			<Menu
				theme="dark"
				selectedKeys={[current]}
				mode="horizontal"
				items={items}
			/>
		</AntdHeader>
	);
}
